import { Component, Input } from '@angular/core';
import { transitionMessages } from 'src/app/common/animations/transition-messages.animation';

@Component({
  selector: 'app-stripe-card-input-wrapper',
  templateUrl: './stripe-card-input-wrapper.component.html',
  styleUrls: ['./stripe-card-input-wrapper.component.scss'],
  animations: [transitionMessages],
})
export class StripeCardInputWrapperComponent {
  @Input() label!: string;
  @Input() required?: boolean;
  @Input() error?: string;
}
