import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { confirmPasswordValidator } from 'src/app/common/helpers/validators';
import { AccountService } from 'src/app/common/services/account.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { ButtonPadding, ButtonStyle, ButtonType } from "../../button/button.component";
import { SkeletonLoaderRounding } from '../../skeleton-loader/skeleton-loader.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

const CHANGE_PASSWORD_SUCCESS = 'Password updated successfully';

@Component({
  selector: 'app-user-change-password-settings',
  templateUrl: './user-change-password-settings.component.html',
  styleUrls: ['./user-change-password-settings.component.scss'],
})
export class UserChangePasswordSettingsComponent implements OnInit {
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _spinnerService = inject(SpinnerService);
  private _toastrService = inject(ToastrService);
  private _destroyRef = inject(DestroyRef);

  readonly formGroup = this._formBuilder.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(8)]],
    confirmNewPassword: ['', [Validators.required, Validators.minLength(8)]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmNewPassword.addValidators(
      confirmPasswordValidator(this.formGroup.controls.newPassword)
    );

    this.formGroup.controls.newPassword.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.formGroup.controls.confirmNewPassword.updateValueAndValidity();
    });
  }

  onReset(): void {
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const { currentPassword, newPassword } = this.formGroup.value;

    this._spinnerService.show();

    this._accountService.changeUserPassword(this._accountService.user()!.id, currentPassword, newPassword).subscribe({
      next: () => {
        this._spinnerService.hide();
        this._toastrService.success(CHANGE_PASSWORD_SUCCESS);
        this.onReset();
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }
}
