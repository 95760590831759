import { Component, inject } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs';
import { RegistrationState } from 'src/app/common/models/registration-state.enum';
import { AccountService } from 'src/app/common/services/account.service';
import {
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";

@Component({
  selector: 'app-add-payment-dialog',
  templateUrl: './add-payment-dialog.component.html',
  styleUrls: ['./add-payment-dialog.component.scss'],
})
export class AddPaymentDialogComponent {
  private _accountService = inject(AccountService);
  private _matDialogRef = inject(MatDialogRef<AddPaymentDialogComponent>);

  readonly registrationState$ = this._accountService.getOrganization().pipe(
    map((organization) => organization?.registrationState)
  );
  readonly RegistrationState = RegistrationState;
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  onCancel(): void {
    this._matDialogRef.close(false);
  }

  onAddPayment(): void {
    this._matDialogRef.close(true);
  }
}
