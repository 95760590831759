import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component, DestroyRef,
  ElementRef, inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { transitionMessages } from 'src/app/common/animations/transition-messages.animation';
import { Country } from 'src/app/common/services/countries.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  animations: [transitionMessages],
})
export class CountrySelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() countries!: Country[];
  @Input() countryControl!: UntypedFormControl;
  @Input() formElement?: HTMLFormElement;

  @ViewChild('countriesInput') countriesInput: ElementRef<HTMLInputElement>;
  @ViewChild('countriesViewport') countriesViewport: CdkVirtualScrollViewport;
  @ViewChildren('countriesInputOverlay') countriesInputOverlays: QueryList<ElementRef<HTMLInputElement>>;

  private _destroyRef = inject(DestroyRef);

  countriesInputActive: boolean = true;
  countriesInputInvalid: boolean = false;
  countriesAutocompleteOpen: boolean = false;
  countryChosen: boolean = false;
  filteredCountries: Country[] = [];
  lastChosenCountry?: Country;

  ngOnInit(): void {
    this.countryControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      // We keep the whole `Country` object in `countryControl.value`
      // Therefore we can receive either a `Country` or a `null` from the outside
      // In case of `Country` we simply use it to populate our select
      // In case of `null` we clear it out (`null` means `countryControl` reset)
      // In case of a non-null value (in our case - an empty string) we fallback to the last chosen country
      this.lastChosenCountry = value instanceof Country ? value : value !== null ? this.lastChosenCountry : undefined;
      this.updateFilteredCountries(value);
    });

    this.formElement?.addEventListener('submit', this.updateCountriesInputInvalid.bind(this));
  }

  ngAfterViewInit(): void {
    const { height } = this.countriesInput.nativeElement.getBoundingClientRect();

    this.countriesInputOverlays.forEach((countryInputOverlay) => {
      countryInputOverlay.nativeElement.style.setProperty('height', `${height}px`);
    });
  }

  ngOnDestroy(): void {
    this.formElement?.removeEventListener('submit', this.updateCountriesInputInvalid);
  }

  onCountriesInputKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }

    // Prevent Enter from submitting while autocomplete is open
    event.preventDefault();
  }

  onCountriesInputClick(): void {
    // Clear out country value so one can start typing (usability)
    this.countryControl.setValue('');
  }

  onCountriesInputFocus(event: FocusEvent): void {
    // Hide selected option
    this.countriesInputActive = true;

    const relatedTarget = event.relatedTarget as HTMLElement | null;

    // Focus is also triggered upon clicking a `mat-option`
    if (relatedTarget?.tagName === 'MAT-OPTION') {
      return;
    }

    // Clear out country value so one can start typing (usability)
    this.countryControl.setValue('');
  }

  onCountriesInputBlur(): void {
    // Manage autocomplete input if country has not been chosen
    if (!this.countryChosen) {
      const countryName = this.countryControl.value;
      const country = this.countries.find((country) => country.name === countryName);

      if (country) {
        // Account for autofill
        this.countryControl.setValue(country);
        // Show selected option
        this.countriesInputActive = false;
      } else if (this.lastChosenCountry) {
        // Try to fallback to the last chosen country (if any)
        this.countryControl.setValue(this.lastChosenCountry);
      } else {
        // Clear out search value
        this.countryControl.setValue('');
      }

      // Manually handle displaying required error
      this.updateCountriesInputInvalid();
    } else {
      this.countryChosen = false;
    }
  }

  onCountriesOptionSelected(): void {
    this.countryChosen = true;
  }

  onCountriesOptionClick(): void {
    this.countryChosen = true;
    // Show selected option
    this.countriesInputActive = false;
    // Blur out the input
    this.countriesInput.nativeElement.blur();
  }

  onCountriesOptionMouseDown(event: Event): void {
    // Prevent the input element from getting blurred
    event.preventDefault();
  }

  onCountriesAutocompleteOpened(): void {
    // Hide required error
    this.countriesAutocompleteOpen = true;
  }

  onCountriesAutocompleteClosed(): void {
    // Show required error
    this.countriesAutocompleteOpen = false;
    // Manually handle displaying required error
    this.updateCountriesInputInvalid();
  }

  private updateFilteredCountries(value?: string | Country): void {
    if (!value) {
      this.filteredCountries = this.countries.slice();
      // Hide selected option
      this.countriesInputActive = true;
      return;
    }

    if (value instanceof Country) {
      // Show selected option
      this.countriesInputActive = false;
      return;
    }

    this.filteredCountries = this.countries.filter((country) =>
      country.name.toLowerCase().startsWith(value.toLowerCase())
    );
  }

  private updateCountriesInputInvalid(): void {
    this.countriesInputInvalid = this.countryControl.invalid;
  }
}
