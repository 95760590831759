import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    _paq: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  private readonly gTagId = environment.gTagId;
  private readonly matomoSiteId = environment.matomoSiteId;

  constructor(private router: Router) {
    this.initializeGoogleAnalytics();
    this.initializeMatomo();
    this.trackPageViews();
  }

  private initializeGoogleAnalytics() {

    // Load the Cookiebot script
    const cookieBotScript = document.createElement('script');
    cookieBotScript.src = "https://consent.cookiebot.com/uc.js";
    cookieBotScript.setAttribute('data-cbid', '60f31a85-9e16-4b7f-95f3-d1c7f9c2a5bf');
    cookieBotScript.setAttribute('data-blockingmode', 'none');
    document.head.appendChild(cookieBotScript);

    // Load the gtag script
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gTagId}`;
    document.head.appendChild(gTagManagerScript);

    // Initialize GA4
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this.gTagId}');
    `;
    document.head.appendChild(gaScript);
  }

  private initializeMatomo() {
    // Matomo Cookiebot integration
    const matomoCookieBotScript = document.createElement('script');
    matomoCookieBotScript.innerHTML = `
      var waitForTrackerCount = 0;
      function matomoWaitForTracker() {
        if (typeof _paq === 'undefined' || typeof Cookiebot === 'undefined') {
          if (waitForTrackerCount < 40) {
            setTimeout(matomoWaitForTracker, 250);
            waitForTrackerCount++;
            return;
          }
        } else {
          window.addEventListener('CookiebotOnAccept', function (e) {
              consentSet();
          });
          window.addEventListener('CookiebotOnDecline', function (e) {
              consentSet();
          })
        }
      }
      function consentSet() {
        if (Cookiebot.consent.statistics) {
          _paq.push(['rememberCookieConsentGiven']);
          _paq.push(['setConsentGiven']);
        } else {
          _paq.push(['forgetCookieConsentGiven']);
          _paq.push(['deleteCookies']);
        }
      }
      document.addEventListener('DOMContentLoaded', matomoWaitForTracker);
    `;
    document.head.appendChild(matomoCookieBotScript);

    // Matomo initialization
    const matomoScript = document.createElement('script');
    matomoScript.innerHTML = `
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['requireCookieConsent']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push(['setDomains', ['localhost', '*.step.dev', '*.exense.ch', '*.stepcloud.ch', '*.stepcloud-test.ch']]);
      _paq.push(['enableCrossDomainLinking']);
      (function() {
        var u="https://exense.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '${this.matomoSiteId}']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://cdn.matomo.cloud/exense.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    `;
    document.head.appendChild(matomoScript);
  }

 private trackPageViews() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // The following doesn't seem to be required anymore. GA seems to automatically detect the page_path
        //window.gtag!('config', this.gTagId, { page_path: event.urlAfterRedirects });
        if (window._paq) {
          // Notify Matomo of the new page view
          window._paq.push(['setCustomUrl', event.urlAfterRedirects]);
          window._paq.push(['setDocumentTitle', document.title]);
          window._paq.push(['trackPageView']);
        }
      }
    });
  }
}
