import { Component, inject, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { BillingPeriod, CustomerPrices } from 'src/app/common/models/billing';
import { PLUGINS, Plugin, PluginType } from 'src/app/common/services/plugins.token';

export type PluginTypesControlValue = PluginType[];

@Component({
  selector: 'app-plugins-configuration',
  templateUrl: './plugins-configuration.component.html',
  styleUrls: ['./plugins-configuration.component.scss'],
})
export class PluginsConfigurationComponent implements OnInit {
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() pluginTypesControl = new UntypedFormControl([]);

  _plugins = inject(PLUGINS);

  readonly pluginByType: Partial<Record<PluginType, Plugin>> = {};
  readonly activeByPluginType: Partial<Record<PluginType, boolean>> = {};
  readonly PluginType = PluginType;

  pluginTypes: PluginType[] = [];
  allPluginsAdded: boolean = false;

  ngOnInit(): void {
    this._plugins.forEach((plugin) => {
      this.pluginByType[plugin.type] = plugin;
    });
  }

  onAddPlugin(pluginType: PluginType): void {
    this.activeByPluginType[pluginType] = true;
    this.updatePluginTypes();
    this.updatePluginTypesControl();
    this.updateAllPluginsAdded();
  }

  onRemovePlugin(pluginType: PluginType): void {
    delete this.activeByPluginType[pluginType];
    this.updatePluginTypes();
    this.updatePluginTypesControl();
    this.updateAllPluginsAdded();
  }

  private updatePluginTypes(): void {
    this.pluginTypes = Object.keys(this.activeByPluginType) as PluginType[];
  }

  private updatePluginTypesControl(): void {
    this.pluginTypesControl.setValue(this.pluginTypes);
  }

  private updateAllPluginsAdded(): void {
    this.allPluginsAdded = this._plugins.every((plugin) => this.activeByPluginType[plugin.type]);
  }
}
