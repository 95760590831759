import { Pipe, PipeTransform } from '@angular/core';
import { Billing } from "../models/billing";

@Pipe({
  name: 'extractBalance',
})
export class ExtractBalancePipe implements PipeTransform {
  transform(billing: Billing): any {
    if (billing?.starting_balance === undefined || billing?.total === undefined) {
      return '';
    }

    const balanceString = billing.currency.toUpperCase() + ' ' + -1 * (billing.starting_balance + billing.total) / 100;
    return balanceString;
  }
}
