import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { confirmPasswordValidator } from 'src/app/common/helpers/validators';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import {
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { AccountService } from '../../../common/services/account.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export const PASSWORD_CHANGE_SUCCESS = 'Password changed successfully';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  private _accountService = inject(AccountService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);
  private _formBuilder = inject(UntypedFormBuilder);
  private _toastrService = inject(ToastrService);
  private _spinnerService = inject(SpinnerService);
  private _destroyRef = inject(DestroyRef);

  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this._formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
  });

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmPassword.addValidators(confirmPasswordValidator(this.formGroup.controls.password));

    this.formGroup.controls.password.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });
  }

  onSubmit(): void {
    if (this._spinnerService.visible) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    const requestId = this._route.snapshot.params['id'];
    const { password } = this.formGroup.value;

    this._spinnerService.show();

    this._accountService.passwordResetVerify(requestId, password).subscribe({
      next: () => {
        this._spinnerService.hide();
        this._toastrService.success(PASSWORD_CHANGE_SUCCESS);
        this._router.navigate(['/login']);
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }
}
