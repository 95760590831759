import { Component, EventEmitter, inject, Output } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap } from 'rxjs';
import { AccountService } from 'src/app/common/services/account.service';
import { BillingService } from 'src/app/common/services/billing.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { CardSettingsFormSubmitParams } from "../card-settings/card-settings.component";

const PAYMENT_METHOD_SETUP_SUCCESS = 'Payment method added successfully, finalizing billing setup now...';
const PAYMENT_METHOD_SETUP_ERROR = 'Something went wrong while processing payment method, please try again.';

@Component({
  selector: 'app-billing-details-step',
  templateUrl: './billing-details-step.component.html',
  styleUrls: ['./billing-details-step.component.scss'],
})
export class BillingDetailsStepComponent {
  @Output() stepComplete = new EventEmitter<void>();

  private _accountService = inject(AccountService);
  private _billingService = inject(BillingService);
  private _spinnerService = inject(SpinnerService);
  private _toastrService = inject(ToastrService);

  onSubmit({ formValue, stripeInstance }: CardSettingsFormSubmitParams): void {
    this._spinnerService.show();

    this._billingService
      .initPaymentSetup()
      .pipe(
        switchMap(({ clientSecret }) =>
          stripeInstance.confirmCardSetup(clientSecret, {
            payment_method: {
              card: formValue.cardNumberElement,
              billing_details: {
                email: this._accountService.user()?.email,
                // Use the cardholder name instead of `userValue?.name` as these are billing details
                name: formValue.cardName,
              },
            },
          })
        ),
        tap(({ error }) => {
          if (error) {
            throw new Error(error.message);
          }

          this._toastrService.success(PAYMENT_METHOD_SETUP_SUCCESS);
        }),
        switchMap(() => this._billingService.getCustomerWithPayment()),
        tap((data) => {
          if (!data?.default_payment_method) {
            // FIXME: sometimes customer is not updated yet leading to an error message even if the setup was successful
            // throw new Error(PAYMENT_METHOD_SETUP_ERROR);
          }
        }),
      )
      .subscribe({
        next: () => {
          this._spinnerService.hide();
          this._accountService.refreshOrganizationUntilRegistrationStateChange();
          this._accountService.refreshUserUntilRegistrationStateChange();
          this.stepComplete.emit();
        },
        error: (error) => {
          this._spinnerService.hide();
          this._toastrService.error(error);
        },
      });
  }
}
