import { Component, effect, inject, Injector, OnInit, runInInjectionContext } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AccountService } from 'src/app/common/services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from '../../button/button.component';
import { Organization } from "../../../models/organization";
import { User } from "../../../models/user";

@Component({
  selector: 'app-user-personal-info-settings',
  templateUrl: './user-personal-info-settings.component.html',
  styleUrls: ['./user-personal-info-settings.component.scss'],
})
export class UserPersonalInfoSettingsComponent implements OnInit {
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  readonly formGroup = this._formBuilder.group({
    organizationName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly injector = inject(Injector);

  ngOnInit() {
    runInInjectionContext(this.injector, () => {
        effect(() => {
          const user = this._accountService.user();
          const organization = this._accountService.organization();

          if (user && organization) {
            this.updateFields(user, organization);
          }
        });
      });
  }

  updateFields(user: User, organization: Organization): void {
    const { email } = user;
    const { name: organizationName } = organization;

    this.formGroup.reset({
      organizationName,
      email,
    });

    this.formGroup.disable();
  }
}
