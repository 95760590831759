import { Component, Input } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { BillingPeriod, CustomerPrices } from 'src/app/common/models/billing';

@Component({
  selector: 'app-cluster-configuration',
  templateUrl: './cluster-configuration.component.html',
  styleUrls: ['./cluster-configuration.component.scss'],
})
export class ClusterConfigurationComponent {
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() nameControl = new UntypedFormControl('');
}
