import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import {
  SkeletonLoaderRounding
} from "src/app/common/components/skeleton-loader/skeleton-loader.component";
import { Billing } from '../../../common/models/billing';
import { BillingService, DataLine } from '../../../common/services/billing.service';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _billingService = inject(BillingService);

  readonly tableColumns: string[] = ['description', 'quantity', 'price', 'amount'];
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  loading: boolean = true;
  dataLines: DataLine[] = [];
  invoice?: Billing;

  ngOnInit(): void {
    this.loading = true;
    this._billingService.getInvoices().subscribe({
      next: (invoices) => {
        const invoiceId = this._route.snapshot.params['id'];
        const invoice = invoices.find((invoice) => invoice.number === invoiceId);

        if (!invoice) {
          return;
        }

        this.invoice = invoice;
        this.dataLines = invoice.lines.data;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
