import { Component, Input } from '@angular/core';
import { Agent } from 'src/app/common/services/agents.token';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss'],
})
export class AgentDetailsComponent {
  @Input() agent!: Agent;
  @Input() agentCount!: number;
}
