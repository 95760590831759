import { Component, inject, OnInit } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap } from 'rxjs';
import { CardDetails } from 'src/app/common/models/card-details';
import { AccountService } from 'src/app/common/services/account.service';
import { BillingService } from 'src/app/common/services/billing.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { CardSettingsFormSubmitParams } from "../../card-settings/card-settings.component";
import { SkeletonLoaderRounding } from "../../skeleton-loader/skeleton-loader.component";

const CARD_DETAILS_UPDATE_SUCCESS = 'Card details updated successfully';

@Component({
  selector: 'app-payment-card-settings',
  templateUrl: './payment-card-settings.component.html',
  styleUrls: ['./payment-card-settings.component.scss'],
})
export class PaymentCardSettingsComponent implements OnInit {
  private _accountService = inject(AccountService);
  private _billingService = inject(BillingService);
  private _spinnerService = inject(SpinnerService);
  private _toastrService = inject(ToastrService);

  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  loading: boolean = false;
  editMode: boolean = false;
  cardDetails?: CardDetails;

  ngOnInit() {
    this.loading = true;
    this._billingService.getCardsDetails().subscribe({
      next: ([cardDetails]) => {
        this.cardDetails = cardDetails;
        this.editMode = !cardDetails;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  setEditMode(editMode: boolean): void {
    this.editMode = editMode;
  }

  onSubmit({ formValue, stripeInstance }: CardSettingsFormSubmitParams): void {
    if (this._spinnerService.visible) {
      return;
    }

    this._spinnerService.show();

    this._billingService
      .initPaymentSetup()
      .pipe(
        switchMap(({ clientSecret }) =>
          stripeInstance.confirmCardSetup(clientSecret, {
            payment_method: {
              card: formValue.cardNumberElement,
              billing_details: {
                email: this._accountService.user()?.email,
                // Use the cardholder name instead of `userValue?.name` as these are billing details
                name: formValue.cardName,
              },
            },
          })
        ),
        tap(({ error }) => {
          if (error) {
            throw new Error(error.message);
          }
        }),
        switchMap(() => this._billingService.getCardsDetails())
      )
      .subscribe({
        next: ([cardDetails]) => {
          this._spinnerService.hide();
          this._toastrService.success(CARD_DETAILS_UPDATE_SUCCESS);
          this.cardDetails = cardDetails;
          this.editMode = false;
        },
        error: (error: string) => {
          this._spinnerService.hide();
          this._toastrService.error(error);
        },
      });
  }
}
